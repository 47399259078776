import {esDefinido} from "./general";  
import config from "./config";
import axios from "axios"; 
export function dmsADecimal(latitude,ref){
    console.log("DMS A DECIMAL");
    console.log(latitude);

    let res = latitude[0] + latitude[1]/60 + latitude[2]/3600;
    console.log(res);
    res *= (ref=="W" || ref=="s")? -1: 1;
    console.log(res);
    return res;
}

function obtenerGlobal(ubicacion){
    let tipos_deseados = [
        "administrative_area_level_1",
        "country"
    ];
    let components = ubicacion.address_components;
    for(let i = 0; i<tipos_deseados.length; i++){
        let busqueda = components.find(comp =>comp.types.includes(tipos_deseados[i]));
        if(esDefinido(busqueda)){
            return busqueda.long_name
        }
    }
    return null;
}

function obtenerLocal(ubicacion){
    let tipos_deseados= [
        "administrative_area_level_5",
        "administrative_area_level_4",
        "administrative_area_level_3",
        "administrative_area_level_2",
        "locality",
        "sublocality",
        "road"];
    let components = ubicacion.address_components;
    for(let i = 0; i<tipos_deseados.length; i++){
        let busqueda = components.find(comp =>comp.types.includes(tipos_deseados[i]));
        if(esDefinido(busqueda)){
            return busqueda.long_name
        }
    }
    return null;
}

function procesarUbicacionGoogle(ubicacion){
    
    let result = "";
    
   console.log("PUG");
    let local = obtenerLocal(ubicacion);
    let global = obtenerGlobal(ubicacion);
    console.log(local);
    console.log(global);
    if(esDefinido(local) && esDefinido(global)){
        return local + ", " + global;
    }
    if(esDefinido(local)){
        return local;
    }
    if(esDefinido( global)){
        return global;
    };
    return result;
}
export async function obtenerLugar(latitud, longitud){
     console.log("OBTENER LUGAR");
     console.log(latitud);
     console.log(longitud);
    let url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitud},${longitud}&key=${config.googleApiKey}`;
    if (latitud === "" || longitud === "" || isNaN(latitud) || isNaN(longitud)){
        return "";
    }
    let query =  await axios.get(url);
    let result = null;
    if (query.data.status === "OK" && query.data.results.length > 0){
        console.log("HAY RESULTADOS DE UBICACION");
        console.log(query.data.results[0]);
        result = procesarUbicacionGoogle(query.data.results[0]);
    }
    else{
        console.log("ERROR AL OBTENER LUGAR: "+latitud + ", " +longitud);
        console.log(query.data.status);
        result = "";
    }
    console.log("OBTENIDO LUGAR");
    console.log(result);
    return result;
}



